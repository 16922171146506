<template>
  <nav>
    <v-app-bar
      v-scroll="onScroll"
      z-index="99999"
      dark
      :color="color"
      app
      elevate-on-scroll
      :height="$vuetify.breakpoint.mdAndUp ? 64 : 48"
    >
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

      <v-spacer class="hidden-sm-and-up" />

      <v-toolbar-title class="headline">
        <router-link to="/">
          <v-avatar class="mr-3" size="48" tile>
            <v-img
              contain
              :src="require(`@/assets/logos/twin-noback-white.png`)"
              :class="[
                $vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp
              ]"
            />
          </v-avatar>
        </router-link>

        <template>
          <span class="font-weight-light">Twin</span>
          <span class="font-weight-bold">Streams</span>
        </template>
        <!--
        <template v-else>
          <span class="font-weight-light">Twin</span>
          <span class="font-weight-bold">Streams</span>
        </template> -->
      </v-toolbar-title>

      <v-spacer class="hidden-xs-only" />

      <!--  <div id="ofBar">
        <b>Please abide by national COVID-19 regulations on the farm:</b>

        <v-row justify="center"> -->
      <!--       <v-dialog
            v-model="dialog"
            width="600px"
          >
            <template v-slot:activator="{ on }"> -->
      <!--    <v-btn
            href="https://www.gov.za/covid-19/resources/regulations-and-guidelines-coronavirus-covid-19"
            color="error"
            dark
            x-small
          >
            Open Statement
          </v-btn> -->
      <!--  </template> -->
      <!--  <v-card>
              <div>
                <v-img
                  height="70vh"
                  :src="require('@/assets/logos/covid-update.png')"
                  contain
                />
              </div>
              <v-card-title class="headline text-center">
                <span>CONSTANTIA UITSIG COVID-19</span>
                <span>Protocol Update</span>
              </v-card-title>
              <v-card-text class="subtitle text-center">
                In accordance with level 3 regulations Constantia Uitsig wine farm will be partially opened from Monday 1 June 2020.
                <br>Strict social distancing and sanitation rules will apply to protect our employees and visitors.
                <br>The Wine shop will be in operating within the prescribed trading hours.
                <br>
                <b>Monday to Thursday 9 am to 5 pm.</b>
                <br>Only one customer at a time will be allowed in the shop and only card payments will be accepted.
                <br>Some of the restaurants and the Bike park on the farm will also operate limited services.
                <br>These businesses will enforce their own rules as required.
                <br>Some of the restaurants will prepare take away meals for their customers who will be allowed on to the farm for picking up these meals.
                <br>Everyone entering the farm will have to undergo a temperature screening test.
                <br>No ablution facilities will be open to the public.
                <br>Delivery services such as Uber will have to pick up deliveries at a designated area outside the main gate.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
                >
                  Agree
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
      <!--   </v-row>
      </div> -->

      <v-spacer class="hidden-xs-only" />

      <v-btn
        to="/"
        class="font-weight-light hidden-sm-and-down ml-2"
        large
        tile
        text
      >
        Home
      </v-btn>

      <v-menu
        transition="slide-x-transition"
        open-on-hover
        offset-y
        attach
        close-delay="0"
        class="elevation-0"
        bottom
        right
        tile
      >
        <template v-slot:activator="{ on }">
          <v-btn
            to="/nursery"
            class="font-weight-light hidden-sm-and-down ml-2"
            large
            text
            tile
            v-on="on"
          >
            Nursery
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-card style="border-radius: 0px;" outlined>
          <v-list content-class="elevation-0">
            <v-list-item to="/about" @click="() => {}">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
            <v-list-item to="/retail-nursery" @click="() => {}">
              <v-list-item-title>Retail Nursery</v-list-item-title>
            </v-list-item>
            <v-list-item to="/wholesale-nursery" @click="() => {}">
              <v-list-item-title>Wholesale Nursery</v-list-item-title>
            </v-list-item>
            <v-list-item to="/plants" @click="() => {}">
              <v-list-item-title>Plant-guide</v-list-item-title>
            </v-list-item>
            <v-list-item to="/walks" @click="() => {}">
              <v-list-item-title>Walks and Cycling</v-list-item-title>
            </v-list-item>
            <v-list-item to="/history" @click="() => {}">
              <v-list-item-title>History</v-list-item-title>
            </v-list-item>
            <v-list-item to="/birding" @click="() => {}">
              <v-list-item-title>Birding</v-list-item-title>
            </v-list-item>
            <v-list-item to="/specials" @click="() => {}">
              <v-list-item-title>Specials</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-btn
        to="/consulting"
        class="font-weight-light hidden-sm-and-down ml-2"
        large
        tile
        text
      >
        Consulting
      </v-btn>
      <v-btn
        to="/landscaping"
        class="font-weight-light hidden-sm-and-down ml-2"
        tile
        large
        text
      >
        Landscaping
      </v-btn>
      <v-btn
        to="/restaurant"
        class="font-weight-light hidden-sm-and-down ml-2"
        tile
        large
        text
      >
        Restaurant
      </v-btn>
      <v-btn
        to="/contact"
        class="font-weight-light hidden-sm-and-down ml-2"
        tile
        large
        text
      >
        Contact/Visit
      </v-btn>

      <!-- <v-list>
        <v-list-tile
          v-for="link in links"
          :key="link.children.text"
          router
          :to="link.children.route"
        >
          <v-list-tile-title>{{ link.children.text }}</v-list-tile-title>
        </v-list-tile>
      </v-list>-->

      <!-- <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="font-weight-light hidden-sm-and-down ml-2" large text flat v-on="on">
          <span>Experience</span>
          <v-icon Right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item) in items" :key="item" @click="item">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </v-menu>-->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed light temporary app>
      <div class="pa-3">
        <v-avatar class="mr-3" size="48" tile>
          <v-img
            contain
            :src="require(`@/assets/logos/twin-noback-green.png`)"
            :class="[
              $vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp
            ]"
          />
        </v-avatar>
        <span class="font-weight-light">Twin</span>
        <span class="font-weight-bold">Streams</span>
      </div>

      <v-list>
        <v-list-item to="/">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content to="/nursery">
              <v-list-item-title to="/about" @click="() => {}">
                Nursery
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <div class="px-3">
            <v-list-item to="/about" @click="() => {}">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
            <v-list-item to="/retail-nursery" @click="() => {}">
              <v-list-item-title>Retail Nursery</v-list-item-title>
            </v-list-item>
            <v-list-item to="/wholesale-nursery" @click="() => {}">
              <v-list-item-title>Wholesale Nursery</v-list-item-title>
            </v-list-item>
            <v-list-item to="/plants" @click="() => {}">
              <v-list-item-title>Plant Guide</v-list-item-title>
            </v-list-item>
            <v-list-item to="/walks" @click="() => {}">
              <v-list-item-title>Walks and Cycling</v-list-item-title>
            </v-list-item>
            <v-list-item to="/history" @click="() => {}">
              <v-list-item-title>History</v-list-item-title>
            </v-list-item>
            <v-list-item to="/birding" @click="() => {}">
              <v-list-item-title>Birding</v-list-item-title>
            </v-list-item>
            <v-list-item to="/walks" @click="() => {}">
              <v-list-item-title>Walks and Cycling</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-group>
        <v-list-item to="/consulting">
          <v-list-item-title>Consulting</v-list-item-title>
        </v-list-item>
        <v-list-item to="/landscaping">
          <v-list-item-title>Landscaping</v-list-item-title>
        </v-list-item>
        <v-list-item to="/specials">
          <v-list-item-title>Specials</v-list-item-title>
        </v-list-item>
        <v-list-item to="/restaurant">
          <v-list-item-title>Restaurant</v-list-item-title>
        </v-list-item>
        <v-list-item to="/contact">
          <v-list-item-title>Contact/Visit</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  name: "CoreAppBar",

  data: () => ({
    drawer: false,
    hasColor: false,
    dialog: false
  }),

  computed: {
    ...mapState("app", ["links"]),
    color() {
      return this.hasColor ? "rgb(0,0,0,0.5)" : "transparent";
    }
  },

  created() {
    this.items = this.$store.state.items.map(item => {
      return Object.assign({}, item, { model: false });
    });
  },

  methods: {
    ...mapMutations("app", ["toggleDrawer"]),
    onScroll() {
      this.hasColor = window.scrollY > 50;
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
.v-menu__content {
  border-radius: 2px;
}
</style>
